.results-title {
    padding-bottom: 5px;
    padding-left: 15px;
    margin-right: 15px;

    :after {
        content: "";
        display: table;
        clear: both;
    }

    span {
        float: left;
        margin-top: -12px;
        padding-right: 25px;
    }

    hr {
        border:1px solid #c9c9c9;
    }
}
