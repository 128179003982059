.header-box {
    background-color: #ffffff;
    color: inherit;
    padding: 15px 20px 20px 20px;
    border-top: 3px solid #e7eaec;

    h2 {
        font-weight: bold !important;
    }
}

.top-navigation .wrapper.header-bar-wrapper {
    padding-bottom: 0;
}

.top-navigation .wrapper.wrapper-content {
    padding: 20px;
}
