// Import Inspinia styles
@import "../inspinia/scss/style";

// Import variables
@import "./_variables";

// Import custom styles
@import "./custom";

@import "./helpers";

// Import custom components
@import "./components/_box";
@import "./components/_form";
@import "./components/_navbar";
@import "./components/_chart";
@import "./components/_header";
@import "./components/_legend";
@import "./components/_results";
@import "./components/_site-tabs";
@import "./components/_subheader-tabs";
@import "./components/_spinner";
@import "./components/_table";
@import "./components/_label";

body,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.cbu-ibox {
    margin-top: 25px;
}

.label-ok {
    background-color: $green;
    color: #fff;
}

.loading-session-spinner {
    float: right;
}

@media (max-width: 1500px) {
    .row-below-1500 {
        width: auto;
        flex: 0 0 100%;
    }
}

#custom-launcher {
    position: fixed;
    bottom: 0;
    right: 0;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin: 0 1em .75em 0;
    border-radius: 15px;
    padding: 1em;
    width: 250px;
    height: 80px;
    background-color: rgb(0, 30, 137);
    border: none;
}

#custom-launcher svg {
    fill: rgb(255, 255, 255);
}

#custom-launcher p {
    color: white;
    text-align: left;
}

.warning-border {
    border-color: #f8ac59;
}

.info-border {
    border-color: #1ab394;
}
