.left-align {
    text-align: left;
}

// Extra flex helpers
.flex-nowrap {
    flex-wrap: nowrap;
}

@media (min-width: $screen-lg-min) {
    .flex-lg-nowrap {
        flex-wrap: nowrap;
    }
}

// Padding
.padded-x {
    padding: 0 5px;
}

.mt-1 {
    margin-top: 20px;
}

@media (min-width: $screen-lg-min) {
    .flex-lg-nowrap {
        flex-wrap: nowrap;
    }

    .pl-lg-1 {
        padding-left: 10px;
    }

    .pr-lg-1 {
        padding-right: 10px;
    }
}

// Hide items in collapsed
[data-toggle="collapse"].collapsed .collapse-open {
    display: none;
}

[data-toggle="collapse"]:not(.collapsed) .collapse-closed {
    display: none;
}
