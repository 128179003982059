.navbar-header {
    padding: 10px;
    background-color: #fff;
}

.navbar-right {
    float: right;
}

.nav {
    min-height: 60px;
}

.navbar-collapse {
    background-color: #001e89;
}

.top-navigation .nav {
    & > li > a {
        padding: 20px 7px;
        color: #fff;

        @media (min-width: $screen-lg-min) {
            padding: 20px;
        }
    }

    & > li.active > a,
    & > li:hover > a,
    & > li:focus > a,
    & > li.open > a,
    & > li.open > a:hover,
    & > li.open > a:focus {
        background-color: #fff;
    }
}

button.navbar-toggle {
    margin-top: 4px;
    background-color: #e7e7e7;
}

.wrapper.wrapper-content + .wrapper.wrapper-content {
    padding-top: 0;
}

@media screen and (max-width: $screen-sm-max) {
    .navbar-collapse {
        width: 100%;
        padding: 0;
        padding-bottom: 20px;
    }

    .navbar-header {
        width: 100%;
    }

    .navbar-default .navbar-nav {
        width: 100%;
        margin: 0;
    }

    .navbar-nav > li {
        margin: 0;
        position: relative;
        display: block;
        line-height: 20px;
        border-bottom: 1px solid #e7e7e7;
    }
}
