// Breakpoint variables
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

$screen-sm-max: $screen-md-min - 1;
$screen-md-max: $screen-lg-min - 1;
$screen-lg-max: $screen-xl-min - 1;
$screen-xl-max: 9999px;

// Colors
$color-green: #1ab394;
$color-yellow: #f8ac59;
$color-red: #ed5565;
$color-grey: #d1dade;

$color-dark-blue: #23527c;
$color-light-blue: #31708f;
