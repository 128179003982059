.form-buttonbar {
    margin-bottom: 0;
    padding: 15px 20px;

    .form-group {
        margin-bottom: 0;
    }

    .btn {
        margin-bottom: 0;
    }

    .btn-right {
        float: right;
        margin-left: 3px;
    }
}

.form-item {
    display: inline-block;
    margin-right: 20px;

    label {
        font-weight: 400;

        > input {
            margin-right: 5px;
        }
    }
}

.filterset-btn {
    margin-top: 23px;
}

.btn-fullwidth {
    width: 100%;
}
