.label {
    &--score {
        display: inline-block;
        float: none !important;
        font-size: 12px;
        line-height: 16px;
        min-width: 80px;
        padding: 8px 10px;
        color: white;

        > div {
            display: inline-block;
            float: none;

            &.label-value {
                &::after {
                    content: " / ";
                }
            }
        }
    }
}
