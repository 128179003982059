.legend {
    margin-top: 10px;

    &--pie-chart {
        margin-top: 30px;
    }

    &__item {
        display: inline-block;
        margin-right: 20px;

        &--full-width {
            display: block;
            margin-top: 5px;
        }
    }

    &__color {
        display: inline-block;
        height: 20px;
        width: 60px;
        background-color: #000;

        &--green {
            background-color: #1ab394;
        }

        &--yellow {
            background-color: #f8ac59;
        }

        &--red {
            background-color: #ed5565;
        }

        &--grey {
            background-color: #d1dade;
        }
    }

    &__text {
        vertical-align: super;
    }

    &__sub {
        margin-top: 10px;
        font-size: 11px;
        font-style: italic;
    }

}
