.invisible-table {
    border-collapse: collapse;

    &__row {
        margin-bottom: 10px;

        & > td {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    &__column {
        padding-right: 30px;
    }
}

.table {
    @extend table;
    margin-bottom: 0;
}

.pagespeed_scores {
    float: left;
    width: 50%;
}

.table_th {
    width: 250px;
}

.table-break {
    word-break: break-all;
}

.table-scrollable {
    position: relative;
    height: 275px;
    overflow: auto;

    &--large {
        height: 600px;
        overflow-y: auto;
    }

    &--sticky-head {
        thead {
            th {
                top: 0;
                position: sticky;
                background-color: #f9f9f9;
            }
        }
    }
}

@media (min-width: 550px) and (max-width: 1260px) {
    .pagespeed_scores {
        width: 100%;
    }
}

@media (min-width: 300px) and (max-width: 550px) {
    .table_th {
        width: 225px;
    }

    .pagespeed_scores {
        width: 100%;
    }
}

@media (min-width: 300px) and (max-width: 768px) {
    .table-striped-reversed > tbody > tr:nth-child(even) > td,
    .table-striped-reversed > tbody > tr:nth-child(even) > th {
        background-color: #f9f9f9;
    }

    .table-striped-reversed > tbody > tr:nth-child(odd) > td,
    .table-striped-reversed > tbody > tr:nth-child(odd) > th {
        background-color: #fff;
    }
}

.table--big {
    width: 100%;
    .table-container {
        overflow: scroll;
    }
}

table.table--score {
    tr td {
        vertical-align: middle;
        border-top: none;

        &:last-child {
            text-align: right;
        }
    }

    a {
        text-decoration: underline;
    }

    .total-row {
        background-color: white;
        border-top: 2px solid #e7eaec;

        td {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }
}
