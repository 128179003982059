.site-tabs {
    background: #fff;
    border-top: 3px solid #e7eaec;
    border-bottom: 1px solid #e7eaec;
    margin-bottom: 40px;
    font-weight: 500;

    ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        flex: auto;
        border-bottom: 1px solid #e7eaec;
        margin-bottom: -1px;

        &:not(:first-of-type) {
            border-left: 1px solid #e7eaec;
        }

        &:last-child {
            border-right: 1px solid #e7eaec;
        }
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 46px;
        text-align: center;
        padding: 20px 10px;
        color: #5f5f5f;

        &:hover {
            color: #001e89;
        }
    }

    li.active {
        border-bottom-color: #001e89;
        z-index: 1;

        border-left-color: #001e89;
        border-right-color: #001e89;

        + li {
            border-left-color: #001e89;
        }

        a {
            color: #fff;
            background: #001e89;
            position: relative;
        }

        a:after {
            content: "";
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #001e89;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 100%);
        }
    }
}

@media (max-width: $screen-md-max) {
    .site-tabs {
        li {
            flex: 1 1 50%;
        }
    }
}

@media (min-width: $screen-xl-min) {
    .site-tabs a {
        padding: 20px;
    }
}
