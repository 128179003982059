.text-no-wrap {
    white-space: nowrap;
}

.external-urls li {
    margin-bottom: 10px;
}

.borderless tbody tr td,
.borderless tbody tr th {
    border: none;
    padding: 1px;
}

#page-wrapper {
    margin: 0;
    margin-top: 51px;
    position: static;
}

.tooltip-demo a {
    color: #001e89;
}

.ibox-tools {
    .tooltip-demo a {
        font-size: 18px;
        vertical-align: top;
        line-height: 1;
    }

    a.btn-info {
        color: #fff;
    }

    form {
        padding-left: 5px;
    }
}

th[title] {
    cursor: help;
}

.navbar-default .btn-link {
    font-size: 14px;
    min-height: 50px;
    padding: 20px;
    color: #fff;
    border: 0;

    &:hover {
        color: $navy;
        background-color: #fff;
    }
}

.ibox .progress {
    margin-bottom: 0;
}

.ibox td .progress {
    width: 100px;
}

.contact-information {
    font-size: 18px;
}

.asc {
    position: relative;

    &::after {
        content: "\f077";
        color: #337ab7;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 11px;
        position: relative;
        right: -4px;
        top: -1px;
    }
}

.desc {
    position: relative;

    &::after {
        content: "\f078";
        color: #337ab7;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 11px;
        position: relative;
        right: -4px;
        top: -1px;
    }
}

.wrapper > .alert {
    margin-left: -15px;
    margin-right: -15px;
}

.btn-info {
    background-color: $green;
    border-color: $green;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info:active:focus,
.btn-info:active:hover,
.btn-info.active:hover,
.btn-info.active:focus {
    background-color: darken($green, 3%);
    border-color: darken($green, 3%);
}

.sk-spinner-three-bounce.sk-spinner {
    margin: $spin-margin;
    width: 40px;
    text-align: center;

    div {
        width: 10px;
        height: 10px;
    }
}

span.tooltip-demo {
    margin-left: 5px;
}

// Added flexbox style bootstrap to support equal column height
@media (min-width: #{$screen-lg-min}) {
    .flex {
        &-row {
            display: flex;
            flex-wrap: wrap;
        }

        &-col-lg-6 {
            display: flex;
            height: inherit;
            float: none;
            width: calc(50% - 1px);

            .ibox {
                display: flex;
                width: 100%;
                height: inherit;
                flex-direction: column;
                background-color: #fff;

                &-content {
                    flex-grow: 1;
                }
            }
        }
    }
}

.link-table {
    margin: 20px 0;

    td:not(:last-child) {
        word-break: break-word;
    }
}

.bad-metric {
    color: #f00;
}

.good-metric {
    color: green
}

.improvements {
    &__table {
        .status {
            padding: 10px 0;
        }

        .status.error {
            color: #f00;
        }

        > tbody > tr > td {
            position: relative;
        }

        .collapsed .improvements__chevron {
            transform: rotate(0);
        }
    }

    &__label {
        border-radius: 4px;
        display: inline-block;
        color: #f8ac59;
        font-size: 90%;
        padding: 2px 0;
        white-space: nowrap;
        position: relative;
        top: -1.5px;
    }

    &__link {
        display: block;
        padding: 8px;

        &,
        &:active,
        &:focus,
        &:hover {
            color: #636363;
        }
    }

    &__title {
        p:first-child {
            margin-top: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    &__content {
        padding: 0 8px 8px 8px;

        p:first-child {
            margin-top: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    &__title,
    &__content {
        code {
            background-color: transparent;
            color: #14bef1;
            padding: 0;
        }
    }

    &__chevron {
        transition: transform 0.35s ease-in;
        transform: rotate(180deg);
    }
}

.italic {
    font-style: italic;
}


ul.days-ago-list {
    margin: 0px;
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.strong {
    font-weight: bold;
}



.form-search-external-links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    label {
        flex: 0 0 100%;
        margin-top: 20px;
    }

    input,
    button {
        flex: 1 1 40%;
    }

    input {
        margin-right: 20px;
    }
}

.form-search-broken-links {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    label {
        white-space: nowrap;
        margin: 0 10px 0 0;
    }

    input + label {
        margin-left: 20px;
    }

    button {
        margin-left: 20px;
    }
}
