.box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 100px;
    min-width: 200px;
    background-color: transparent;
    border: 1px solid transparent;

    &--blue {
        background-color: #3498db;
        color: white;
    }

    &--purple {
        background-color: #884ea0;
        color: white;
    }

    &--orange {
        background-color: #f8ac59;
        color: white;
    }

    &--bordered {
        border: 1px solid $light-gray;
        border-left: transparent;
    }
}

.box-container {
    display: flex;
    margin-bottom: 3rem;
}

.box-title {
    display: flex;
    align-items: center;

    span {
        font-size: 1.25rem;
        margin-left: 0.5rem;
    }
}

.box-value {
    font-size: 3.5rem;
}
